import React from 'react';

import ZoomIcon from './ZoomIcon';

import styles from './styles.module.scss';

interface ZoomButtonProps {
  ariaLabel: string;
  onClick: () => void;
}

const ZoomButton: React.FunctionComponent<ZoomButtonProps> = ({ ariaLabel, onClick }) => (
  <button type="button" className={styles.zoom} aria-label={ariaLabel} onClick={onClick} title={ariaLabel}>
    <ZoomIcon />
  </button>
);

export default ZoomButton;
