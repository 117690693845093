import React, { useState, useEffect, useRef } from 'react';

import { createHashRouter, RouterProvider, useRouteError, useSearchParams } from 'react-router-dom';

import Button from '@helsenorge/designsystem-react/components/Button';
import Icon from '@helsenorge/designsystem-react/components/Icon';
import PlusLarge from '@helsenorge/designsystem-react/components/Icons/PlusLarge';
import Label from '@helsenorge/designsystem-react/components/Label';
import LinkList from '@helsenorge/designsystem-react/components/LinkList';
import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';
import RadioButton from '@helsenorge/designsystem-react/components/RadioButton';
import { useIsServerSide } from '@helsenorge/designsystem-react/hooks/useIsServerSide';

import Filter from '@helsenorge/filter/components/filter';
import FilterSearch from '@helsenorge/filter/components/filter/filter-search';
import { useFilters } from '@helsenorge/filter/components/filter/useFilters';

import { clinicalTrialsLoader, ClinicalTrialsLoaderData, useClinicalTrialsLoaderData } from './loader';
import { ClinicalTrial, RelatertDiagnose } from '../../_api/ClinicalTrialsApi';
import { updateHitsRefLength } from '../../_helpers/refs';
import {
  toUniqueValues,
  stringIsInArray,
  stringEqualsString,
  stringIncludesString,
  sortAlphabetically,
  stringIsInArrayOfObjects,
} from '../../_helpers/string';

export const CLINICAL_TRIALS_ID = 'studier';
export const FALLBACK_ERROR_MESSAGE = 'Feil: Kunne ikke laste kliniske studier';

interface ClinicalTrialsBlockProps {
  title: string;
  clinicalTrialsApiUrl: string;
  snowstormApiUrl: string;
  snomedCTBranch: string;
  resources?: {
    loadingError?: string;
    search?: string;
    category?: string;
    status?: string;
    relevantTreatment?: string;
    responsibleOrganization?: string;
    studyTakesPlaceAt?: string;
    searchFilteredBy?: string;
    resetFilters?: string;
    singleClinicalTrial?: string;
    multipleClinicalTrials?: string;
    showMore?: string;
  };
  trialsPerPage?: number;
}

export interface ClinicalTrialsStorage {
  timestamp: number;
  kliniskeStudier: ClinicalTrial[];
}

export type ClinicalTrialsForm = {
  kategori: string;
  status: string;
  relatertBehandling: string;
  utfortAv: string;
  deltakendeForetak: string;
  query: string;
};

export const ClinicalTrialsBlock: React.FunctionComponent<ClinicalTrialsBlockProps> = ({ title, resources, trialsPerPage = 25 }) => {
  const [page, setPage] = useState(1);
  const { clinicalTrials: kliniskeStudier, concept } = useClinicalTrialsLoaderData();
  const [searchParams, setSearchParams] = useSearchParams();

  const { filters, activeFilters, searchQuery, setSearchQuery, setFilter } = useFilters<ClinicalTrialsForm>({
    defaultValues: {
      kategori: searchParams.get('kategori') ?? '',
      status: searchParams.get('status') ?? '',
      relatertBehandling: searchParams.get('relatertBehandling') ?? '',
      utfortAv: searchParams.get('utfortAv') ?? '',
      deltakendeForetak: searchParams.get('deltakendeForetak') ?? '',
      query: searchParams.get('query') ?? '',
    },
  });

  const handleFilterChange = (key: keyof ClinicalTrialsForm, value: string): void => {
    setSearchParams(
      { ...searchParams, ...(value ? { [key]: value } : undefined) },
      {
        replace: true,
      }
    );
    setFilter(key, value);
  };

  const handleSearchQueryChange = (value: string): void => {
    setSearchParams(
      { ...searchParams, ...(searchQuery ? { query: searchQuery } : undefined) },
      {
        replace: true,
      }
    );
    setSearchQuery(value);
  };

  const kategorier = kliniskeStudier
    .map(({ kategorier }) => kategorier)
    .flat()
    .reduce(toUniqueValues, [] as string[])
    .sort(sortAlphabetically);

  const statuser = kliniskeStudier
    .map(({ status }) => status)
    .reduce(toUniqueValues, [] as string[])
    .sort(sortAlphabetically);

  const relaterteBehandlinger = kliniskeStudier
    .map(({ relaterte_behandlinger: behandlinger }) => behandlinger ?? [])
    .flat()
    .reduce(toUniqueValues, [] as string[])
    .sort(sortAlphabetically);

  const utfortAv = kliniskeStudier
    .map(({ utfort_av: utfortAv }) => utfortAv)
    .reduce(toUniqueValues, [] as string[])
    .sort(sortAlphabetically);

  const deltakendeForetak = kliniskeStudier
    .map(({ deltakende_foretak: deltakendeForetak }) => deltakendeForetak ?? [])
    .flat()
    .reduce(toUniqueValues, [] as string[])
    .sort(sortAlphabetically);

  const filtrerteKliniskeStudier = kliniskeStudier
    .filter(x => !concept?.id || stringIsInArrayOfObjects<RelatertDiagnose>(concept?.id, x.relaterte_diagnoser, 'diagnose_kode'))
    .filter(x => !filters?.status || stringEqualsString(filters.status, x.status))
    .filter(x => !filters?.utfortAv || stringEqualsString(filters.utfortAv, x.utfort_av))
    .filter(x => !filters?.kategori || stringIsInArray(filters.kategori, x.kategorier))
    .filter(x => !filters?.relatertBehandling || stringIsInArray(filters.relatertBehandling, x.relaterte_behandlinger))
    .filter(x => !filters?.deltakendeForetak || stringIsInArray(filters.deltakendeForetak, x.deltakende_foretak))
    .filter(x => !searchQuery || stringIncludesString(searchQuery, `${x.tittel} ${x.sammendrag}`));

  const filtrerteKategorier = filtrerteKliniskeStudier
    .map(({ kategorier }) => kategorier)
    .flat()
    .reduce(toUniqueValues, [] as string[]);

  const filtrerteStatuser = filtrerteKliniskeStudier.map(({ status }) => status).reduce(toUniqueValues, [] as string[]);

  const filtrerteRelaterteBehandlinger = filtrerteKliniskeStudier
    .map(({ relaterte_behandlinger: behandlinger }) => behandlinger ?? [])
    .flat()
    .reduce(toUniqueValues, [] as string[]);

  const filtrerteUtfortAv = filtrerteKliniskeStudier.map(({ utfort_av: utfortAv }) => utfortAv).reduce(toUniqueValues, [] as string[]);

  const filtrerteDeltakendeForetak = filtrerteKliniskeStudier
    .map(({ deltakende_foretak: deltakendeForetak }) => deltakendeForetak ?? [])
    .flat()
    .reduce(toUniqueValues, [] as string[]);

  const linkRefList = useRef<React.RefObject<HTMLLIElement>[]>([React.createRef()]);

  linkRefList.current = updateHitsRefLength<HTMLLIElement>(filtrerteKliniskeStudier.length);

  const moreTrials = filtrerteKliniskeStudier.length > page * trialsPerPage;

  const handleShowMoreTrials = (): void => {
    setPage(page + 1);
  };

  useEffect(() => {
    // Set focus to the first of the newly added trials
    linkRefList?.current[(page - 1) * trialsPerPage]?.current?.focus();
  }, [page, trialsPerPage]);

  return (
    <div className="default-block-spacing" id={CLINICAL_TRIALS_ID}>
      <div className="mb-6">
        <Filter
          resources={{
            filterTitle: title ?? 'Find a clinical trial',
          }}
          isAlwaysOpen={false}
          showFilters
          activeFilters={activeFilters}
          searchFilter={
            <FilterSearch
              onChange={(e?: React.ChangeEvent<HTMLInputElement>) => handleSearchQueryChange(e?.target.value ?? '')}
              defaultValue={searchQuery}
              onSearch={() => handleSearchQueryChange(searchQuery ?? '')}
            />
          }
          filterOptions={[
            {
              type: 'dropdown',
              label: resources?.category ?? 'Category',
              placeholder: resources?.category ?? 'Category',
              children: kategorier.map(kategori => (
                <RadioButton
                  key={kategori}
                  name="kategori"
                  value={kategori}
                  checked={filters?.kategori === kategori}
                  disabled={!filtrerteKategorier.includes(kategori)}
                  label={<Label labelTexts={[{ text: kategori }]} />}
                  onChange={e => handleFilterChange('kategori', e.target.checked ? e.target.value : '')}
                />
              )),
            },
            {
              type: 'dropdown',
              label: resources?.status ?? 'Status',
              placeholder: resources?.status ?? 'Status',
              children: statuser.map(status => (
                <RadioButton
                  key={status}
                  name="status"
                  value={status}
                  checked={filters?.status === status}
                  label={<Label labelTexts={[{ text: status }]} />}
                  disabled={!filtrerteStatuser.includes(status)}
                  onChange={e => handleFilterChange('status', e.target.checked ? e.target.value : '')}
                />
              )),
            },
            {
              type: 'dropdown',
              label: resources?.relevantTreatment ?? 'Relevant treatment',
              placeholder: resources?.relevantTreatment ?? 'Relevant treatment',
              children: relaterteBehandlinger.map(behandling => (
                <RadioButton
                  key={behandling}
                  name="relatertBehandling"
                  value={behandling}
                  checked={filters?.relatertBehandling === behandling}
                  label={<Label labelTexts={[{ text: behandling }]} />}
                  disabled={!filtrerteRelaterteBehandlinger.includes(behandling)}
                  onChange={e => handleFilterChange('relatertBehandling', e.target.checked ? e.target.value : '')}
                />
              )),
            },
            {
              type: 'dropdown',
              label: resources?.responsibleOrganization ?? 'Responsible organization',
              placeholder: resources?.responsibleOrganization ?? 'Responsible organization',
              children: utfortAv.map(foretak => (
                <RadioButton
                  key={foretak}
                  name="utfortAv"
                  value={foretak}
                  checked={filters?.utfortAv === foretak}
                  label={<Label labelTexts={[{ text: foretak }]} />}
                  disabled={!filtrerteUtfortAv.includes(foretak)}
                  onChange={e => handleFilterChange('utfortAv', e.target.checked ? e.target.value : '')}
                />
              )),
            },
            {
              type: 'dropdown',
              label: resources?.studyTakesPlaceAt ?? 'Study takes place at',
              placeholder: resources?.studyTakesPlaceAt ?? 'Study takes place at',
              children: deltakendeForetak.map(foretak => (
                <RadioButton
                  key={foretak}
                  name="deltakendeForetak"
                  value={foretak}
                  checked={filters?.deltakendeForetak === foretak}
                  label={<Label labelTexts={[{ text: foretak }]} />}
                  disabled={!filtrerteDeltakendeForetak.includes(foretak)}
                  onChange={e => handleFilterChange('deltakendeForetak', e.target.checked ? e.target.value : '')}
                />
              )),
            },
          ]}
        />
      </div>

      <div className="row bg-neutral50 pt-7 pb-8">
        <div className="col-12 col-md-10 offset-md-1">
          <p className="mt-0 mb-6 mb-md-7" role="alert" aria-live="polite">
            <strong>
              {filtrerteKliniskeStudier.length}{' '}
              {filtrerteKliniskeStudier.length === 1
                ? (resources?.singleClinicalTrial ?? 'clinical trial')
                : (resources?.multipleClinicalTrials ?? 'clinical trials')}
            </strong>
          </p>
          {filtrerteKliniskeStudier.length > 0 && (
            <LinkList color="neutral">
              {filtrerteKliniskeStudier.slice(0, page * trialsPerPage).map((kliniskStudie, index) => (
                <LinkList.Link key={kliniskStudie.id} ref={linkRefList.current[index]} href={kliniskStudie.lenke}>
                  {kliniskStudie.tittel}
                </LinkList.Link>
              ))}
            </LinkList>
          )}
          {moreTrials && (
            <Button concept="normal" variant="outline" onClick={handleShowMoreTrials} wrapperClassName="mt-6 ml-3 ml-md-9">
              <Icon svgIcon={PlusLarge} />
              {resources?.showMore ?? 'Show more'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const ErrorElement: React.FC = () => {
  const error = useRouteError();

  if (error instanceof Error) {
    return <NotificationPanel variant="error">{error.message}</NotificationPanel>;
  }
  if (typeof error === 'string') {
    return <NotificationPanel variant="error">{error}</NotificationPanel>;
  }
  return <NotificationPanel variant="error">{FALLBACK_ERROR_MESSAGE}</NotificationPanel>;
};

const ClinicalTrialsBlockContainer: React.FC<ClinicalTrialsBlockProps> = props => {
  const isServerSide = useIsServerSide();

  if (isServerSide) {
    return null;
  }

  const router = createHashRouter([
    {
      path: '*',
      loader: ({ request }): Promise<ClinicalTrialsLoaderData> =>
        clinicalTrialsLoader(
          request,
          props.clinicalTrialsApiUrl,
          props.snowstormApiUrl,
          props.snomedCTBranch,
          props.resources?.loadingError ?? FALLBACK_ERROR_MESSAGE
        ),
      shouldRevalidate: (): boolean => false,
      element: <ClinicalTrialsBlock {...props} />,
      errorElement: <ErrorElement />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default ClinicalTrialsBlockContainer;
