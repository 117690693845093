import { useState, useRef, useEffect } from 'react';

import { Breakpoint, useBreakpoint } from '@helsenorge/designsystem-react';

// Get the height in pixels of an element
export const getHeightOfElement = (element: HTMLElement): number => {
  const { height = 0 } = element.getBoundingClientRect() ?? {};
  return height;
};

/**
 * Finds first visible element from a list of elements
 * @param targetRefs targetRefs: List of elements to check
 * @param offsetRef offsetRef: Element to use as an offset (eg. a sticky element that hides content underneath it)
 * @param attribute Attribute to get value of
 * @returns A function to smooth scroll to an element, and optionally the value of an attribute of that element
 */
export const useScrollingElementList = (
  targetRefs: React.MutableRefObject<React.RefObject<HTMLElement>[]>,
  offsetRef?: React.RefObject<HTMLElement>,
  attribute?: string
): {
  scrollToElementByIndex: (index: number) => void;
  attributeValue?: string;
} => {
  const ticking = useRef(false);
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint < Breakpoint.md;
  const [attributeValue, setAttributeValue] = useState<string>();

  const scrollToElementByIndex = (index: number): void => {
    const offset =
      offsetRef?.current && !isMobile // Ignore offset element on mobile
        ? getHeightOfElement(offsetRef.current)
        : 0;
    if (targetRefs.current[index].current) {
      const { top: distanceFromWindowToElement = 0 } = targetRefs.current[index].current?.getBoundingClientRect() ?? {};
      window.scrollBy({
        top: distanceFromWindowToElement - offset - 16,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const handleScroll = (): void => {
      ticking.current = false;

      const heightOfOffsetElement =
        offsetRef?.current && !isMobile // Ignore offset element on mobile
          ? getHeightOfElement(offsetRef.current)
          : 0;
      const first = targetRefs.current.find(element => {
        const { bottom: distanceToBottomOfElement = 0, height: heightOfElement = 0 } = element.current?.getBoundingClientRect() ?? {};
        return distanceToBottomOfElement - heightOfElement * 0.382 > heightOfOffsetElement;
      });
      if (attribute) {
        setAttributeValue(first?.current?.getAttribute(attribute) ?? undefined);
      }
    };

    const onScroll = (): void => {
      // Throttle the scroll listener to around 60 fps (or whatever the web browser thinks is OK)
      if (!ticking.current) {
        requestAnimationFrame(handleScroll);
      }
      ticking.current = true;
    };

    window.addEventListener('scroll', onScroll, false);
  }, [isMobile, offsetRef, targetRefs, attribute]);

  return { scrollToElementByIndex, attributeValue };
};
