import { fetchUtility } from '../_helpers/fetch';

export type RelatertDiagnose = {
  diagnose_kode: string;
  segment?: string | null;
};

export interface ClinicalTrial {
  id: string;
  tittel: string;
  kategorier: string[];
  sammendrag?: string | null;
  status: string;
  utfort_av: string;
  deltakende_foretak: string[] | null;
  relaterte_behandlinger: string[] | null;
  relaterte_diagnoser: RelatertDiagnose[] | null;
  lenke: string;
  cristinId?: string;
  stikktittel?: string;
}

export const getClinicalTrialsItem = (id: string, endpoint: string): Promise<ClinicalTrial[] | null> =>
  fetchUtility<ClinicalTrial[]>(`${endpoint}?sctid=${id}`, {
    method: 'GET',
  });

export const getClinicalTrials = (endpoint: string): Promise<ClinicalTrial[] | null> =>
  fetchUtility<ClinicalTrial[]>(endpoint, {
    method: 'GET',
  });
