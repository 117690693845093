import Articles from '@helsenorge/core-cms/articles';
import ExpanderBlock from '@helsenorge/core-cms/behandlingsvalg/ekspanderendeblock';
import ResourceBlock from '@helsenorge/core-cms/behandlingsvalg/ressursblock';
import Breadcrumb from '@helsenorge/core-cms/breadcrumb';
import DiveBoard from '@helsenorge/core-cms/diveboard';
import FaktaBlock from '@helsenorge/core-cms/faktablock';
import HeaderSkeleton from '@helsenorge/core-cms/header-skeleton';
import ImageBlock from '@helsenorge/core-cms/image';
import LoginBlock from '@helsenorge/core-cms/loginblock';
import LoginButton from '@helsenorge/core-cms/loginblock/LoginButtonWrapper';
import PromoBoard from '@helsenorge/core-cms/promoboard';
import Promopanel from '@helsenorge/core-cms/promopanel';
import SearchBox from '@helsenorge/core-cms/search';
import SearchBlock from '@helsenorge/core-cms/search/block';
import SurveyInvitation from '@helsenorge/core-cms/survey-invitation';
import Temamenu from '@helsenorge/core-cms/temamenu';
import Tjenestepanel from '@helsenorge/core-cms/tjenestepanel';
import ToolBlock from '@helsenorge/core-cms/toolblock';
import UserFeedbackBlock from '@helsenorge/core-cms/userfeedback';
import VideoMatrise from '@helsenorge/core-cms/videomatrise';
import VideoBlock from '@helsenorge/core-cms/videospiller';

globalThis.HelsenorgeCoreCms = {
  Articles,
  Breadcrumb,
  DiveBoard,
  ExpanderBlock,
  FaktaBlock,
  HeaderSkeleton,
  ImageBlock,
  PromoBoard,
  Promopanel,
  ResourceBlock,
  SearchBlock,
  SearchBox,
  SurveyInvitation,
  Temamenu,
  Tjenestepanel,
  ToolBlock,
  UserFeedbackBlock,
  VideoBlock,
  VideoMatrise,
  LoginBlock,
  LoginButton,
};
