import { useState } from 'react';

export enum KeyCodeEnum {
  Enter = 13,
  Space = 32,
}

// Toggle a boolean value
export const useToggle = (
  initial: boolean
): {
  value: boolean;
  toggleValue: (event?: React.MouseEvent | React.KeyboardEvent) => void;
  handleKeyDown: (event: React.KeyboardEvent) => void;
} => {
  const [value, setValue] = useState(initial);

  const toggleValue = (event?: React.MouseEvent | React.KeyboardEvent): void => {
    if (event) {
      event.preventDefault();
    }
    setValue(!value);
  };

  const handleKeyDown = (event: React.KeyboardEvent): void => {
    const { keyCode } = event;
    if (keyCode === KeyCodeEnum.Enter || keyCode === KeyCodeEnum.Space) {
      toggleValue(event);
    }
  };
  return { value, toggleValue, handleKeyDown };
};
