import React, { useState, useEffect } from 'react';

import { Heading, getHeadingList } from './getHeadingList';
import TableOfContents, { TableOfContentsProps } from './TableOfContents';

type TableOfContentsWrapperProps = Omit<TableOfContentsProps, 'headingList'> & {
  selector: string;
  links: Heading[];
};

const TableOfContentsWrapper: React.FunctionComponent<TableOfContentsWrapperProps> = ({
  title,
  toTheTop,
  headingListAriaLabel,
  selector,
  links,
}) => {
  const [headingList, setHeadingList] = useState<Heading[]>();

  useEffect(() => {
    const clientSideHeadingList = getHeadingList(selector);

    const headingListWithElements = links.map(heading => ({
      ...heading,
      element: clientSideHeadingList.find(x => x.slug === heading.slug)?.element,
    }));

    setHeadingList(headingListWithElements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selector]);

  if (!headingList) {
    return null;
  }

  return <TableOfContents title={title} toTheTop={toTheTop} headingList={headingList} headingListAriaLabel={headingListAriaLabel} />;
};

export default TableOfContentsWrapper;
