import React, { useState, useRef, useEffect } from 'react';

import classNames from 'classnames';

import Loader from '@helsenorge/designsystem-react/components/Loader';
import Title from '@helsenorge/designsystem-react/components/Title';

import SearchBox from '@helsenorge/core-cms/search';
import { SearchArgs } from '@helsenorge/core-cms/types/entities';
import { Breakpoint, useBreakpoint } from '@helsenorge/designsystem-react';
import { trackSearch } from '@helsenorge/framework-utils/adobe-analytics';
import { getSidetittelId } from '@helsenorge/framework-utils/hn-page';

import SearchDidYouMean from './_SearchDidYouMean';
import { SearchHitProps } from './_SearchHit';
import SearchResults from './_SearchResults';
import NoHitsIcon from './NoHitsIcon';
import Dictionary, { DictionaryObject } from '../../_helpers/dictionary';
import { fetchUtility } from '../../_helpers/fetch';
import { updateHitsRefLength } from '../../_helpers/refs';
import { toUrlParameters, UrlParametersObject } from '../../_helpers/urlParams';
import LinkButton from '../../components/Button/LinkButton';
import SafeHTML from '../../components/SafeHtml';

enum SearchPageAction {
  None,
  ReloadSearch,
}

interface SearchPageProps {
  dictionary: DictionaryObject;
  query: string;
  searchUrl: string;
  contentReference?: string;
  disableAutoSuggest?: boolean;
  disableDidYouMean?: boolean;
  referrer?: string;
  noHitsText: string;
  noHitsUrl?: string;
  noHitsLinkTitle?: string;
  searchType: string;
  searchResultDisplayTitle?: string;
  title: string;
  searchResultTitle: string;
}

export interface SearchResultsObject {
  totalHits: number;
  skip: number;
  hits: SearchHitProps[] | null;
}

const emptySearchResults: SearchResultsObject = {
  totalHits: 0,
  skip: 0,
  hits: [],
};

const SearchPage: React.FunctionComponent<SearchPageProps> = ({
  dictionary,
  query,
  contentReference,
  referrer,
  searchUrl,
  noHitsText,
  noHitsUrl,
  noHitsLinkTitle,
  disableAutoSuggest,
  disableDidYouMean,
  searchType,
  searchResultDisplayTitle,
  title,
  searchResultTitle,
}) => {
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [activeSearchResultTitle, setActiveSearchResultTitle] = useState<string>();
  const [currentQuery, setCurrentQuery] = useState(query);
  const [activeQuery, setActiveQuery] = useState(query);
  const [returnedResults, setReturnedResults] = useState<SearchResultsObject | null>(null);
  const [collectedResults, updateCollectedResults] = useState<SearchHitProps[]>([]);
  const [didYouMean, setDidYoumean] = useState<string[] | null>(null);
  const [currentHitCount, setCurrentHitCount] = useState(0);
  const [totalHitCount, setTotalHitCount] = useState(0);
  const breakpoint = useBreakpoint();

  const searchHitRefs = useRef<React.RefObject<HTMLAnchorElement>[]>([React.createRef()]);

  const resetSearchParameters = (): void => {
    setReturnedResults(emptySearchResults);
    setDidYoumean(null);
    updateCollectedResults([]);
    setCurrentHitCount(0);
    setTotalHitCount(0);
    setCurrentQuery('');
    setActiveSearchResultTitle(undefined);
  };

  const getSearchResults = async (
    { skip, contentReference, query }: SearchArgs,
    pageAction = SearchPageAction.ReloadSearch
  ): Promise<void> => {
    const reloadPage = pageAction === SearchPageAction.ReloadSearch;

    if (!query?.trim()) {
      resetSearchParameters();
      return;
    }

    if (reloadPage) setLoading(true);

    let urlParams: UrlParametersObject = {
      q: query,
      skip: skip ?? currentHitCount,
    };

    if (contentReference) urlParams = { ...urlParams, contentReference: contentReference };

    try {
      const url = `${searchUrl}search${toUrlParameters(urlParams)}`;
      const searchResult = await fetchUtility<SearchResultsObject>(url);

      setActiveSearchResultTitle(searchResultTitle.replace('{query}', query));
      setError('');

      setReturnedResults(searchResult);
      if (searchResult?.hits) {
        if (reloadPage) {
          updateCollectedResults(searchResult.hits);
        } else {
          updateCollectedResults([...collectedResults, ...searchResult.hits]);
        }

        const numberOfHits = (reloadPage ? 0 : currentHitCount) + searchResult.hits.length;

        searchHitRefs.current = updateHitsRefLength<HTMLAnchorElement>(numberOfHits);

        trackSearch(query, searchType, searchResult.totalHits);

        setCurrentHitCount(numberOfHits);
        setTotalHitCount(searchResult.totalHits);
      }

      if (!disableDidYouMean) {
        try {
          const didYouMean = await fetchUtility<string[]>(`${searchUrl}didyoumean${toUrlParameters({ q: query })}`);
          setDidYoumean(didYouMean);
        } catch {
          // Intentionally not showing did you mean-errors
        }
      }
    } catch {
      resetSearchParameters();
      setError(Dictionary(dictionary, '/searchPage/searchError', 'Feil: Kan ikke hente søkeresultater'));
    } finally {
      setCurrentQuery(query);
      setLoading(false);
    }
  };

  const handleShowMore = async (): Promise<void> => {
    setLoadingMore(true);

    await getSearchResults(
      {
        query: currentQuery,
        skip: currentHitCount,
        contentReference: contentReference,
      },
      SearchPageAction.None
    );

    setLoadingMore(false);
    searchHitRefs?.current[currentHitCount]?.current?.focus();
  };

  const triggerDidYouMean = async (suggestedQuery: string): Promise<void> => {
    setActiveQuery(suggestedQuery);
    await getSearchResults({ query: suggestedQuery, skip: 0 });
  };

  useEffect((): void => {
    document.title = `${activeSearchResultTitle || title} - helsenorge.no`;
  }, [activeSearchResultTitle, title]);

  const renderSearchHitsCount = (): JSX.Element | undefined => {
    if (!returnedResults || !collectedResults || !currentQuery || loading) return;

    const searchHitCountText = `${totalHitCount} ${Dictionary(dictionary, '/searchPage/resultsFor', 'results for')} «${currentQuery}»`;

    return (
      <div className="row no-gutters">
        <div className="col-lg-10 offset-lg-1 row no-gutters">
          <p className="my-6 col-md-10 offset-md-1 mb-0 is-bold" role="status">
            {searchHitCountText}
          </p>
          {!!totalHitCount || (
            <div className="my-6 col-md-10 offset-md-1 mb-0">
              <SafeHTML html={noHitsText} />
              {noHitsUrl && noHitsLinkTitle && (
                <LinkButton href={noHitsUrl} className="mt-md-5" variant="borderless">
                  {noHitsLinkTitle}
                </LinkButton>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12 col-lg-10 offset-lg-1">
          <Title
            id={getSidetittelId()}
            htmlMarkup="h1"
            appearance="title1"
            className={classNames('mt-5 mb-5 col-md-10 offset-md-1', !searchResultDisplayTitle && 'sr-only')}
          >
            {searchResultDisplayTitle || activeSearchResultTitle || title}
          </Title>

          <SearchBox
            contentReference={contentReference}
            referrer={referrer}
            isAutoSuggestDisabled={disableAutoSuggest}
            isSearchAsYouTypeEnabled={false}
            query={activeQuery}
            searchUrl={searchUrl}
            getDirectSearchResults={(searchQuery: SearchArgs): void => {
              getSearchResults({ ...searchQuery, skip: 0 });
            }}
            searchInputId="search-page-search"
            label={Dictionary(dictionary, '/common/search', 'Search')}
            submitLabel={Dictionary(dictionary, '/common/search', 'Search')}
          />
        </div>
      </div>
      {error ? <p className="my-5 text-cherry500 is-semibold">{error}</p> : renderSearchHitsCount()}
      <SearchDidYouMean
        dictionary={dictionary}
        results={didYouMean}
        triggerDidYouMean={(query: string): Promise<void> => triggerDidYouMean(query)}
      />
      {loading && (
        <div className="text-center mt-12" key="loader">
          <Loader size="large" />
        </div>
      )}
      {!!collectedResults.length && !loading && (
        <SearchResults
          isMobile={breakpoint < Breakpoint.md}
          dictionary={dictionary}
          searchHits={collectedResults}
          loadingMore={loadingMore}
          handleShowMore={(): Promise<void> => handleShowMore()}
          totalHitCount={totalHitCount}
          currentHitCount={currentHitCount}
          searchRef={searchHitRefs}
        />
      )}
      {!error && !loading && !totalHitCount && returnedResults && currentQuery && (
        <div className="row">
          <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4 d-flex justify-content-center">
            <div className="search__no-hits-icon-container">
              <NoHitsIcon color="#d7d5d4" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

SearchPage.defaultProps = {
  query: '',
};

export default SearchPage;
