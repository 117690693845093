import { useLoaderData } from 'react-router-dom';

import { ClinicalTrialsStorage } from './ClinicalTrialsBlock';
import { getTimestamp } from './helpers';
import { ClinicalTrial, getClinicalTrials } from '../../_api/ClinicalTrialsApi';
import { getSnomedConceptItem, SnomedConcept } from '../../_api/SnomedCTApi';

const LOCAL_STORAGE_KEY = 'kliniskeStudier';
const ONE_HOUR = 60 * 60;

export interface ClinicalTrialsLoaderData {
  clinicalTrials: ClinicalTrial[];
  concept: SnomedConcept | null;
}

export const clinicalTrialsLoader = async (
  request: Request,
  clinicalTrialsApiUrl: string,
  snowstormApiUrl: string,
  snomedCTBranch: string,
  loadingError: string
): Promise<ClinicalTrialsLoaderData> => {
  const searchParams = new URLSearchParams(request.url);

  let concept = null;

  const diagnose = searchParams.get('diagnose');
  if (diagnose) {
    concept = await getSnomedConceptItem(diagnose, snowstormApiUrl, snomedCTBranch);
  }

  try {
    const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedData) {
      const json = JSON.parse(storedData) as ClinicalTrialsStorage;
      if (getTimestamp() - json.timestamp > ONE_HOUR) {
        throw Error('Local storage data has expired');
      }
      if (!json.kliniskeStudier.length) {
        throw Error('Local storage data is invalid');
      }

      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ timestamp: getTimestamp(), kliniskeStudier: json.kliniskeStudier }));

      return { concept, clinicalTrials: json.kliniskeStudier };
    } else {
      throw Error('Local storage has no data');
    }
  } catch {
    const clinicalTrials = await getClinicalTrials(clinicalTrialsApiUrl);

    if (clinicalTrials) {
      return { concept, clinicalTrials };
    }

    throw Error(loadingError);
  }
};

export const useClinicalTrialsLoaderData = (): ClinicalTrialsLoaderData => useLoaderData() as ClinicalTrialsLoaderData;
