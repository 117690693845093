import { fetchUtility } from '../_helpers/fetch';

export interface SnomedConcept {
  id: string;
  pt: {
    term: string;
  };
}

export interface SnomedSearchResultItem {
  concept: SnomedConcept;
}

export interface SnomedSearchResult {
  items: SnomedSearchResultItem[];
}

const headers: HeadersInit = {
  Accept: 'application/json',
  'Accept-Language': 'no-X-47351000202107,no-X-61000202103,no',
};

export const getSnomedConceptItem = (id: string, apiUrl: string, branch: string): Promise<SnomedConcept | null> =>
  fetchUtility<SnomedConcept>(`${apiUrl}/snowstorm/snomed-ct/${branch}/concepts/${id}`, {
    headers,
  });

export const searchSnomed = (
  query: string,
  apiUrl: string,
  branch: string,
  referenceSet: string | null
): Promise<SnomedSearchResult | null> => {
  const url = new URL(`${apiUrl}/snowstorm/snomed-ct/browser/${branch}/descriptions`);
  url.searchParams.set('limit', '10');
  url.searchParams.set('active', 'true');
  url.searchParams.set('groupByConcept', 'true');
  url.searchParams.set('language', 'no');
  url.searchParams.append('language', 'nb');
  url.searchParams.append('language', 'nn');
  url.searchParams.append('language', 'en');
  url.searchParams.set('conceptActive', 'true');
  if (referenceSet) {
    url.searchParams.set('conceptRefset', referenceSet);
  }
  url.searchParams.set('term', query);

  return fetchUtility<SnomedSearchResult>(url.toString(), {
    headers,
  });
};
