import React from 'react';

const ZoomIcon: React.FC = () => {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9196 8.76465V10.1646H26.6366L22.1176 14.6836L23.1076 15.6746L27.6266 11.1556V15.8396H29.0266V8.76465H21.9196ZM15.6736 23.1086L14.6836 22.1186L10.1646 26.6366V21.9526H8.76459V29.0276H15.8716V27.6276H11.1546L15.6736 23.1086Z"
        fill="white"
      />
    </svg>
  );
};

export default ZoomIcon;
